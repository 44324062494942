import React from 'react';
import './InfoCard.css';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import EkaLogo from "../eka_logo.png";
import MarketCapIcon from "../market.svg";
import CirculatingSupplyIcon from "../market.svg";
import BlockIcon from "../block.svg";
import ValidatorIcon from "../validator.svg";
import { Link } from 'react-router-dom';

const InfoCard = ({ data = {} }) => {
  const {
    ekaPrice = 0,
    priceChange = 0,
    marketCap = 0,
    circulatingSupply = 0,
    latestBlock = 0,
    activeValidators = 0,
    transactionHistory = []
  } = data;

  const mockChartData = [
    { date: 'Oct 8', value: 220000 },
    { date: 'Oct 12', value: 240000 },
    { date: 'Oct 16', value: 230000 },
    { date: 'Oct 20', value: 270000 },
  ];

  const formattedPrice = ekaPrice.toFixed(4);
  const formattedPriceChange = priceChange.toFixed(4);
  const formattedMarketCap = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(marketCap);
  const formattedCirculatingSupply = new Intl.NumberFormat('en-US').format(circulatingSupply);

  return (
    <div className="stats-container">
      <div className="stat-item">
        <div className="icon-holder">
          <img src={EkaLogo} alt="EKA icon" />
        </div>
        <div className="stat-content">
          <div className="stat-title">EKA PRICE</div>
          <div className="stat-value">
            ${formattedPrice} <span className="price-change neutral">
              (<i className='fa fa-caret-up'></i> {  formattedPriceChange}%)
            </span>
          </div>
        </div>
      </div>
      <div className="stat-item">
        <div className="icon-holder">
          <img src={MarketCapIcon} alt="Market cap icon" />
        </div>
        <div className="stat-content">
          <div className="stat-title">MARKET CAP</div>
          <div className="stat-value">{formattedMarketCap}</div>
        </div>
      </div>
      <div className="stat-item">
        <div className="icon-holder">
          <img src={CirculatingSupplyIcon} alt="Circulating supply icon" />
        </div>
        <div className="stat-content">
          <div className="stat-title">CIRCULATING SUPPLY</div>
          <div className="stat-value">{formattedCirculatingSupply} EKA</div>
        </div>
      </div>
      <div className="stats-row">
        <div className="stat-item half-width">
          <div className="icon-holder">
            <img src={BlockIcon} alt="Block icon" />
          </div>
          <div className="stat-content">
            <div className="stat-title">LATEST BLOCK</div>
            <div className="stat-value"> <Link to={"/block/" + latestBlock} className="see-more-link">{latestBlock}</Link></div>
          </div>
        </div>
        <div className="stat-item half-width">
          <div className="icon-holder">
            <img src={ValidatorIcon} alt="Validator icon" />
          </div>
          <div className="stat-content">
            <div className="stat-title">ACTIVE VALIDATORS</div>
            <div className="stat-value">{activeValidators}</div>
          </div>
        </div>
      </div>
      <div className="chart-container">
        <div className="chart-title">EKA TRANSACTION HISTORY LAST 14 DAYS</div>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={mockChartData}>
            <XAxis dataKey="date" tick={{fontSize: 10, fill: '#888888'}} axisLine={false} tickLine={false} />
            <YAxis tick={{fontSize: 10, fill: '#888888'}} axisLine={false} tickLine={false} />
            <Line type="monotone" dataKey="value" stroke="#FFA500" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default InfoCard;